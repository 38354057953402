// Import variables
@import '../../../variables.scss';

// On desktop screens
@media screen and (min-width: ($breakpoint_phone_max + 1)) {
  .container {
    .bubble {
      mix-blend-mode: difference;
      position: fixed;
      pointer-events: none;
    }
    .bubble_1 {
      z-index: 1;
      top: 26vw;
      left: 6vw;

      img {
        width: 7vw;
      }
    }
    .bubble_2 {
      z-index: 1;
      top: 7vw;
      left: 58.3vw;

      img {
        width: 13vw;
      }
    }

    .content_container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .months_container {
        width: 26vw;
      }

      .photo_desktop_left {
        position: fixed;
        width: 26vw;
        left: 9.3vw;

        img {
          width: 100%;
        }

        &_top {
          top: 10vw;
        }
        &_bottom {
          bottom: 0;
        }
      }

      .photo_desktop_right {
        position: fixed;
        width: 26vw;
        right: 9.3vw;

        img {
          width: 100%;
        }

        &_top {
          top: 10vw;
        }
        &_bottom {
          bottom: 0;
        }
      }
    }
  }
}

// On non-desktop screens
@media screen and (max-width: $breakpoint_phone_max) {
  .container {
    .bubble {
      mix-blend-mode: difference;
      position: fixed;
      pointer-events: none;
    }
    .bubble_1 {
      z-index: 1;
      top: -30vw;
      left: 50vw;

      img {
        width: 60vw;
      }
    }
    .bubble_2 {
      z-index: 1;
      top: 117vw;
      left: -6vw;

      img {
        width: 32vw;
      }
    }
    .content_container {
      .mobile_photo {
        margin-bottom: 6vw;
        img {
          width: 100%;
        }
      }
    }
  }
}
