// Import variables
@import '../../../../../variables.scss';

// Desktop screen styles
@media screen and (min-width: $breakpoint_phone_max + 1) {
  .container {
    display: flex;

    .column {
      display: flex;
      flex-direction: column;

      width: 26vw;
      margin-right: 1.7vw;

      &:last-of-type {
        margin-right: 0;
      }

      .item {
        margin-bottom: 1.7vw;

        &:last-of-type {
          margin-bottom: 0vw;
        }
      }
    }
  }
}
