// Import variables
@import '../../../variables.scss';

// Non-desktop screen styles
@media screen and (max-width: $breakpoint_phone_max) {
  .bubble {
    mix-blend-mode: difference;
    position: fixed;
    pointer-events: none;
  }
  .bubble_1 {
    z-index: 1;
    top: 3vw;
    left: 73vw;

    img {
      width: 32vw;
    }
  }
  .bubble_2 {
    z-index: 1;
    top: 82vw;
    left: -25vw;

    img {
      width: 48vw;
    }
  }

  .unit {
    display: flex;
    flex-direction: column;

    .portrait_img {
      position: relative;
      margin-bottom: 9vw;

      img {
        width: 100%;
      }
    }

    .landscape_img {
      position: relative;
      margin-bottom: 9vw;

      img {
        width: 100%;
      }
    }

    .text_column {
      margin-bottom: 9vw;
    }

    .quote_text {
      margin-bottom: 3vw;
      text-transform: uppercase;
    }

    .quote_author_and_magazine {
      margin-bottom: 9vw;
    }
  }
}
