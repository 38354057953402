// Import variables
@import '../../../variables.scss';

// Desktop screen styles
@media screen and (min-width: $breakpoint_phone_max + 1) {
  .bubble {
    mix-blend-mode: difference;
    position: fixed;
    pointer-events: none;
  }
  .bubble_1 {
    z-index: 1;
    top: 15vw;
    left: 3vw;

    img {
      width: 9vw;
    }
  }
  .bubble_2 {
    z-index: -1;
    top: 5.3vw;
    left: 62vw;

    img {
      width: 9vw;
    }
  }
  .bubble_3 {
    z-index: 1;
    top: 35vw;
    left: 55vw;

    img {
      width: 17vw;
    }
  }

  .unit {
    display: flex;
    margin-bottom: 5vw;

    .text_and_landscape_img_de {
      width: 53.7vw;
      margin-right: 1.7vw;
    }
    .text_and_landscape_img_en {
      width: 53.7vw;
      margin-left: 1.7vw;
    }
    .landscape_img {
      position: relative;
      img {
        width: 100%;
      }
    }
    .text_container {
      display: flex;
      flex-direction: row;

      margin-bottom: 3vw;

      .text_column {
        width: 26vw;
        margin-right: 1.7vw;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
    .portrait_img_and_quote {
      width: 26vw;

      .portrait_img {
        position: relative;
        margin-bottom: 10vw;

        img {
          width: 100%;
        }
      }
      .quote_author_and_magazine {
      }
      .quote_text {
        text-transform: uppercase;
      }
    }
  }
}
