// Import variables
@import './variables.scss';

// This is global CSS

// Fonts
@font-face {
  font-family: 'Bastardo Groteskish';
  src: url('./assets/fonts/bastardo_groteskish.woff') format('woff');
}

// Define global font styles

// On desktop screens
@media screen and (min-width: ($breakpoint_phone_max + 1)) {
  // Titles
  .font_title {
    font-family: 'Bastardo Groteskish';
    font-size: 3.1vw;
    line-height: 3.1vw;
  }

  // Navigation text
  .font_menu {
    font-family: 'Bastardo Groteskish';
    font-size: 2vw;
    line-height: 2.3vw;
  }

  // Text
  .font_text {
    font-family: 'Bastardo Groteskish';
    font-size: 1.3vw;
    line-height: 1.7vw;
  }
}

// On non-desktop screens
@media screen and (max-width: $breakpoint_phone_max) {
  // Titles
  .font_title {
    font-family: 'Bastardo Groteskish';
    font-size: 10.3vw;
    line-height: 10.3vw;
  }

  // Navigation text
  .font_menu {
    font-family: 'Bastardo Groteskish';
    font-size: 7.7vw;
    line-height: 9vw;
  }

  // Text
  .font_text {
    font-family: 'Bastardo Groteskish';
    font-size: 5.1vw;
    line-height: 6.6vw;
  }
}

// Style scrollbars

// Firefox
* {
  scrollbar-width: thin;
  scrollbar-color: lightgray white;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0.3vw;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgray;
}

// Unset some defaults and set some compatibility stuff
body {
  overflow-x: hidden;
  background: white;
  font-size: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // Hyphenate text
  hyphens: manual;

  a {
    text-decoration: none;
    color: black;
  }
}

// Since scrolling is handled by react-scrollbars-custom, the root element
//   should always be sized as the viewport.
#root {
  width: 100vw;
  height: 100vh;
}
