// Import variables
@import '../../../../../variables.scss';

// Non-desktop screen styles
@media screen and (max-width: $breakpoint_phone_max) {
  .container {
    margin-top: 3.5vw;
    display: flex;
    flex-direction: column;

    .item {
      margin-bottom: 3.5vw;

      &:last-of-type {
        margin-bottom: 0vw;
      }
    }
  }
}
