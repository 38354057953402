// Import variables
@import '../../../../variables.scss';

// Desktop screen styles
@media screen and (min-width: $breakpoint_phone_max + 1) {
  .container {
    display: flex;
    flex-direction: column;

    margin-bottom: 4vw;

    .name_container {
      .description {
        margin-bottom: 0.3vw;
      }
      .name {
        margin-bottom: 1.3vw;
        text-transform: uppercase;
      }
    }
    .content_container {
      display: flex;

      .vspacer {
        width: 1.7vw;
      }

      .media_container {
        width: 53.7vw;

        .media_emphasized {
          margin-bottom: 1.7vw;
        }
      }

      .text_container {
        width: 26vw;

        .members_container {
          .member {
            margin-bottom: 0.5vw;

            &:last-of-type {
              margin-bottom: 1vw;
            }

            .member_name {
              text-transform: uppercase;
            }
          }
        }

        .text {
          margin-bottom: 1vw;
        }

        .links_container {
          text-transform: uppercase;
        }
      }
    }
  }
}
