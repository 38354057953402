// Import variables
@import '../../../../variables.scss';

// Non-desktop screen styles
@media screen and (max-width: $breakpoint_phone_max) {
  .container {
    display: flex;
    flex-direction: column;

    .entry {
      margin-bottom: 5vw;

      &:last-of-type {
        margin-bottom: 8vw;
      }
    }
  }
}
