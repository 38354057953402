// Import variables
@import '../../variables.scss';

// General
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  height: 100vh;
}

// On desktop screens
@media screen and (min-width: $breakpoint_phone_max + 1) {
  .container {
    margin-top: -10vw;
  }
}
// On non-desktop screens
@media screen and (max-width: $breakpoint_phone_max) {
  .container {
  }
}
