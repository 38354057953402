@import '../../../../variables.scss';

// Desktop
@media screen and (min-width: ($breakpoint_phone_max + 1)) {
  .container {
    margin-bottom: 0.8vw;
    cursor: default;

    &:last-of-type {
      margin-bottom: 1.2vw;
    }

    .content_container {
      display: flex;

      .day {
        width: 4.5vw;
        flex-shrink: 0;
      }

      .name_description_container {
        display: flex;
        flex-direction: column;

        .name {
          width: 21.5vw;
          text-transform: uppercase;
        }
        .description {
        }
      }
    }
  }
}
// Phone
@media screen and (max-width: $breakpoint_phone_max) {
  .container {
    margin-bottom: 3vw;

    &:last-of-type {
      margin-bottom: 8vw;
    }

    .content_container {
      display: flex;

      .day {
        width: 15vw;
        flex-shrink: 0;
      }

      .name_description_container {
        display: flex;
        flex-direction: column;

        .name {
          width: 57vw;
          text-transform: uppercase;
        }
        .description {
        }
      }
    }
  }
}
