// Import variables
@import '../../../../variables.scss';

// Non-desktop screen styles
@media screen and (max-width: $breakpoint_phone_max) {
  .container {
    display: flex;
    flex-direction: column;

    margin-bottom: 24vw;

    &:last-of-type {
      margin-bottom: 8vw;
    }

    .poster {
      margin-bottom: 4.5vw;
      img {
        width: 100%;
      }
    }
    .poster_first {
      margin-top: 0;
      margin-bottom: 3vw;
      img {
        width: 100%;
      }
    }

    .name_container {
      .description {
      }
      .name {
        margin-top: 1vw;
        margin-bottom: 3vw;
        text-transform: uppercase;
      }
    }

    .media_container {
    }

    .text_container {
      .members_container {
        margin-bottom: 3.7vw;

        .member {
          margin-bottom: 1.5vw;
          &:last-of-type {
            margin-bottom: 0vw;
          }

          .member_name {
            text-transform: uppercase;
          }
        }
      }

      .text {
        margin-bottom: 3.7vw;
      }

      .links_container {
        text-transform: uppercase;
        margin-bottom: 5vw;
      }
    }
  }
}
