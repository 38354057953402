// Import variables
@import '../../../../variables.scss';

// Desktop screen styles
@media screen and (min-width: $breakpoint_phone_max + 1) {
  .container {
    .bubble {
      mix-blend-mode: difference;
      position: fixed;
      pointer-events: none;
    }
    .bubble_1 {
      z-index: 1;
      top: 37vw;
      left: 4vw;

      img {
        width: 13vw;
      }
    }
    .bubble_2 {
      z-index: 1;
      top: 3.4vw;
      left: 58.5vw;

      img {
        width: 8vw;
      }
    }

    .entry_container {
      display: flex;
      align-items: flex-start;
      width: 100%;
      min-height: calc(100vh - 10vw);

      .poster {
        position: relative;
        margin-right: 1.7vw;

        img {
          width: 100%;
        }

        &_one_column {
          width: 26vw;
        }

        &_two_columns {
          width: 53.7vw;
        }

        &_pointer {
          cursor: pointer;
        }

        .play_overlay {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.15);
          svg {
            height: 7vw;
            width: 5.95vw;
            fill: rgba(255, 255, 255, 0.72);
          }
        }

        .video {
          width: 100%;
        }
      }
      .text_container {
        width: 26vw;

        .subtitle {
        }
        .title {
          margin-top: 0.3vw;
          text-transform: uppercase;
        }
        .text {
          margin-top: 1vw;
        }
        .links_container {
          margin-top: 1.3vw;
          text-transform: uppercase;
        }
      }
    }
    .more_news_container {
      width: 100%;
      margin-top: 10vw;
      margin-bottom: 5vw;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
