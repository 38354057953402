.container {
  position: relative;
  cursor: pointer;

  img {
    width: 100%;
  }

  .info_overlay {
    position: absolute;
    top: 0;
    bottom: 0.4vw;
    left: 1.1vw;
    right: 0;

    color: white;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .name {
      text-transform: uppercase;
    }
    .description {
      margin-bottom: 0.3vw;
    }
  }
}
