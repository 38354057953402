// Import variables
@import './variables.scss';

// On all screens
.container {
}

// On desktop screens
@media screen and (min-width: $breakpoint_phone_max + 1) {
  .content {
    margin-left: 9.3vw;
    margin-right: 9.3vw;
    padding-top: 10vw;
  }
}
// On non-desktop screens
@media screen and (max-width: $breakpoint_phone_max) {
  .content {
    margin-left: 14vw;
    margin-right: 14vw;
  }
}
