// Import variables
@import '../../../../variables.scss';

// Non-desktop screen styles
@media screen and (max-width: $breakpoint_phone_max) {
  .container {
    .bubble {
      mix-blend-mode: difference;
      position: fixed;
      pointer-events: none;
    }
    .bubble_1 {
      z-index: 1;
      top: 5vw;
      left: 80vw;

      img {
        width: 25vw;
      }
    }
    .bubble_2 {
      z-index: 1;
      top: 80vw;
      left: -40vw;

      img {
        width: 60vw;
      }
    }
  }
}
