// Import variables
@import '../variables.scss';

// General
.container {
  hyphens: none;
}
.hidden {
  display: none;
}

// On desktop screens
@media screen and (min-width: ($breakpoint_phone_max + 1)) {
  .container {
    .logo {
      position: fixed;
      top: 1vw;
      left: 1vw;
      transform-origin: top right;
      transform: translateX(-100%) rotate(-90deg);
    }
    .item_container {
      position: fixed;

      bottom: 1vw;
      right: 1vw;

      width: 43.82vw;

      transform-origin: top right;
      transform: translateY(100%) rotate(90deg);

      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .item {
        height: 1.88vw;
        margin-right: 1.7vw;

        &:last-of-type {
          margin-right: 0;
        }
      }
      .item_highlighted {
        border-bottom: 0.16vw solid black;
      }
      .menu_button {
        cursor: pointer;
        margin-right: 0;
      }
    }
    .item_container_open {
      z-index: 2;
      position: fixed;

      top: 0;
      bottom: 0;
      right: 0;
      left: 64.7vw;

      background-color: black;
      color: white;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;

      a {
        color: white;
      }
      .item {
        height: 2vw;
        margin-right: 8vw;
        margin-bottom: 1vw;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
      .item_highlighted {
        border-bottom: 0.16vw solid white;
      }

      .menu_button {
        position: absolute;
        cursor: pointer;
        right: 1.3vw;
        bottom: 0.8vw;
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }
}

// On non-desktop screens
@media screen and (max-width: $breakpoint_phone_max) {
  .container {
    .logo {
      position: fixed;

      height: 6vw;

      top: 4vw;
      left: 3vw;
      transform-origin: top right;
      transform: translateX(-100%) rotate(-90deg);
    }
    .logo_open {
      z-index: 3;
      color: white;
      a {
        color: white;
      }
    }
    .item_container {
      position: fixed;

      height: 10vw;
      width: 162.9vw;

      bottom: 4vw;
      right: 3vw;
      transform-origin: top right;
      transform: translateY(100%) rotate(90deg);

      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .item {
        height: 7vw;
        margin-right: 5vw;

        &:last-of-type {
          margin-right: 0;
        }
      }
      .item_highlighted {
        border-bottom: 0.5vw solid black;
      }
      .menu_button {
        cursor: pointer;
        margin-right: 0;
      }
    }
    .item_container_open {
      z-index: 2;
      position: fixed;

      top: 0;
      bottom: 0;
      right: 0;
      left: 0;

      padding-top: 68vw;
      padding-bottom: 12vw;

      background-color: black;
      color: white;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;

      a {
        color: white;
      }
      .item {
        height: 8vw;
        margin-right: 12vw;
        margin-bottom: 3.2vw;

        &_vspacer {
          margin-bottom: 0;
          flex-grow: 1;
        }
      }
      .item_highlighted {
        border-bottom: 0.5vw solid white;
      }
      .menu_button {
        position: absolute;
        cursor: pointer;
        right: 4.1vw;
        bottom: 2.5vw;
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }
}
