// Import variables
@import '../../../../variables.scss';

// Desktop screen styles
@media screen and (min-width: $breakpoint_phone_max + 1) {
  .container {
    .bubble {
      mix-blend-mode: difference;
      position: fixed;
      pointer-events: none;
    }
    .bubble_1 {
      z-index: 1;
      top: 28vw;
      left: 2.8vw;

      img {
        width: 10vw;
      }
    }
    .bubble_2 {
      z-index: 1;
      top: 4.9vw;
      left: 82vw;

      img {
        width: 6.5vw;
      }
    }
    .bubble_3 {
      z-index: 1;
      top: 41vw;
      left: 54vw;

      img {
        width: 12vw;
      }
    }

    .projects_container {
      margin-top: 8vw;
    }
  }
}
