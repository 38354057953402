// Import variables
@import '../../../variables.scss';

// Desktop screen styles
@media screen and (min-width: $breakpoint_phone_max + 1) {
}

// Non-desktop screen styles
@media screen and (max-width: $breakpoint_phone_max) {
  .container {
    padding-top: 10vw;
  }
}
