@import '../../../../variables.scss';

// Desktop
@media screen and (min-width: ($breakpoint_phone_max + 1)) {
  .container {
    .date {
      margin-bottom: 0.8vw;
    }
  }
}
// Phone
@media screen and (max-width: $breakpoint_phone_max) {
  .container {
    .date {
      margin-bottom: 6vw;
    }
  }
}
