// This is the stylesheet for the 'react-scrollbars-custom'
//
// It is taken and adapted from:
// - https://github.com/xobotyi/react-scrollbars-custom/blob/gh-pages/src/scss/ExamplesGrid.scss

// Import variables
@import './variables.scss';

.ScrollbarsCustom {
  width: 100%;
  height: 100%;

  & > .ScrollbarsCustom-Wrapper {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    & > .ScrollbarsCustom-Scroller {
      & > .ScrollbarsCustom-Content {
      }
    }
  }

  .ScrollbarsCustom-Track {
    position: absolute;
  }

  .ScrollbarsCustom-Thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 0;

    &:hover,
    &.dragging {
      background: rgba(0, 0, 0, 0.4);
    }
  }

  .ScrollbarsCustom-ThumbY {
    width: 100%;
  }

  .ScrollbarsCustom-TrackY {
    width: $scrollbar_width;
    height: 100%;
    top: 0;
    right: 0;
  }

  &.trackYVisible {
    & > .ScrollbarsCustom-Wrapper {
      //right: $scrollbar_width;
    }
  }
}
