// Import variables
@import '../../../variables.scss';

// On desktop screens
@media screen and (min-width: $breakpoint_phone_max + 1) {
  .container {
    .bubble {
      mix-blend-mode: difference;
      position: fixed;
      pointer-events: none;
    }
    .bubble_1 {
      z-index: 1;
      bottom: 2.8vw;
      left: 3.5vw;

      img {
        width: 20vw;
      }
    }
    .bubble_2 {
      z-index: 1;
      bottom: 19.5vw;
      left: 4.2vw;

      img {
        width: 7vw;
      }
    }
    .bubble_3 {
      z-index: 1;
      top: 15vw;
      right: 20vw;

      img {
        width: 12vw;
      }
    }

    .content_container {
      .mobile_photo {
        display: none;
      }

      .links_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: -10vw;

        min-height: 100vh;

        .link {
          text-transform: uppercase;
        }
      }

      .impressum_container {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        margin-bottom: 0.9vw;
      }
    }
  }
}

// On non-desktop screens
@media screen and (max-width: $breakpoint_phone_max) {
  .container {
    .bubble {
      mix-blend-mode: difference;
      position: fixed;
      pointer-events: none;
    }
    .bubble_1 {
      z-index: 1;
      top: -30vw;
      left: 50vw;

      img {
        width: 60vw;
      }
    }
    .bubble_2 {
      z-index: 1;
      top: 92vw;
      left: -15vw;

      img {
        width: 50vw;
      }
    }

    .content_container {
      display: flex;
      flex-direction: column;
      min-height: 100vh;

      .mobile_photo {
        img {
          width: 100%;
        }
      }

      .links_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        margin-top: 9vw;

        .link {
          text-transform: uppercase;
        }
      }

      .impressum_container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        text-align: center;

        margin-top: 11vw;
        margin-bottom: 3vw;

        font-size: 3.5vw;
        line-height: 4.5vw;

        .vspacer {
          height: 2vw;
        }
      }
    }
  }
}
