// Import variables
@import '../../../../../variables.scss';

// Desktop screen styles
@media screen and (min-width: $breakpoint_phone_max + 1) {
  .container {
    width: 100%;
    .pointer {
      cursor: pointer;
    }
    .poster {
      position: relative;
      img {
        width: 100%;
      }
      .hover_text {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;

        padding: 2vw;

        background-color: rgba(255, 255, 255, 0.3);
        text-transform: uppercase;

        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
  }
}

// Non-desktop screen styles
@media screen and (max-width: $breakpoint_phone_max) {
  .container {
    width: 100%;
    .poster {
      position: relative;
      img {
        width: 100%;
      }
    }
  }
}
