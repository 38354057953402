// Import variables
@import '../../../variables.scss';

// On desktop screens
@media screen and (min-width: $breakpoint_phone_max + 1) {
  .container {
    .bubble {
      mix-blend-mode: difference;
      position: fixed;
      pointer-events: none;
    }
    .bubble_1 {
      z-index: 1;
      top: 12vw;
      left: 4.5vw;

      img {
        width: 8vw;
      }
    }
    .bubble_2 {
      z-index: -1;
      top: 4.5vw;
      left: 30vw;

      img {
        width: 10.5vw;
      }
    }
    .bubble_3 {
      z-index: 1;
      top: 13vw;
      left: 84.5vw;

      img {
        width: 10vw;
      }
    }
    .bubble_4 {
      z-index: -1;
      top: 40vw;
      left: 4vw;

      img {
        width: 8vw;
      }
    }
    .bubble_5 {
      z-index: 1;
      top: 31vw;
      left: 56vw;

      img {
        width: 17vw;
      }
    }
    .bubble_6 {
      z-index: -1;
      top: 45vw;
      left: 82vw;

      img {
        width: 9vw;
      }
    }
  }
}

// On non-desktop screens
@media screen and (max-width: $breakpoint_phone_max) {
  .container {
    .bubble {
      mix-blend-mode: difference;
      position: fixed;
      pointer-events: none;
    }
    .bubble_1 {
      z-index: 1;
      top: 2vw;
      left: 75vw;

      img {
        width: 28vw;
      }
    }
    .bubble_2 {
      z-index: 1;
      top: 75vw;
      left: -4vw;

      img {
        width: 26vw;
      }
    }
  }
}
