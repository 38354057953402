.container {
  .overview_container {
    display: flex;
    min-height: calc(100vh - 10vw);

    .column {
      display: flex;
      flex-direction: column;
      width: 26vw;
      margin-right: 1.7vw;

      &:last-of-type {
        margin-right: 0;
      }

      .overview_item {
        margin-bottom: 1.7vw;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}
