// Import variables
@import '../../variables.scss';

// General (all screen formats)
.container {
  position: relative;

  img {
    width: 100%;
  }

  &_pointer {
    cursor: pointer;
  }

  .play_overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.15);
    svg {
      fill: rgba(255, 255, 255, 0.72);
    }
  }

  .video {
    width: 100%;
  }
}
// Desktop screen styles
@media screen and (min-width: $breakpoint_phone_max + 1) {
  .container {
    .play_overlay {
      svg {
        height: 7vw;
        width: 5.95vw;
      }
    }
  }
}

// Non-desktop screen styles
@media screen and (max-width: $breakpoint_phone_max) {
  .container {
    .play_overlay {
      svg {
        height: 9.1vw;
        width: 7.735vw;
      }
    }
  }
}
