// Import variables
@import '../../../../variables.scss';

// Non-desktop screen styles
@media screen and (max-width: $breakpoint_phone_max) {
  .container {
    .bubble {
      mix-blend-mode: difference;
      position: fixed;
      pointer-events: none;
    }
    .bubble_1 {
      z-index: 1;
      top: -5vw;
      left: 68vw;

      img {
        width: 30vw;
      }
    }
    .bubble_2 {
      z-index: 1;
      top: 70vw;
      left: -14vw;

      img {
        width: 45vw;
      }
    }

    .entry_container {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height: 100vh;

      .poster {
        position: relative;

        img {
          width: 100%;
        }

        .play_overlay {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.15);
          svg {
            height: 9.1vw;
            width: 7.735vw;
            fill: rgba(255, 255, 255, 0.72);
          }
        }

        .video {
          width: 100%;
        }
      }

      .subtitle {
        margin-top: 7vw;
      }
      .title {
        margin-top: 1.5vw;
        text-transform: uppercase;
      }
      .text {
        margin-top: 4vw;
      }
      .links_container {
        margin-top: 3.5vw;
        text-transform: uppercase;
      }
    }
    .more_news_container {
      width: 100%;
      margin-top: 30vw;
      margin-bottom: 10vw;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
